<template>
  <div class="relative pb-20 min-h-screen">
    <main-menu class="z-30"></main-menu>

    <section class="py-20 pt-32" style="min-height: 250px">
      <p class="tracking-wider text-xl md:text-4xl text-center">BERITA</p>
      <span class="block mb-12 text-center">Informasi Seputar Sekolah</span>
      <div
        v-if="news.length > 0"
        class="container px-8 mx-auto flex flex-wrap items-center justify-center gap-8"
      >
        <div
          class="shadow-lg rounded bg-white relative"
          style="max-width: 18rem"
          v-for="(item, key) in news"
          :key="`news-${key}`"
        >
          <img
            loading="lazy"
            :src="item.image_url_full"
            :alt="`Gambar ${item.title}`"
            class="rounded-t shadow bg-green-900"
            style="min-height: 168px"
          />
          <div class="p-4 rounded-b bg-white text-gray-900 w-full">
            <p class="font-bold capitalize mb-1 text-left text-xl">
              {{ item.title }}
            </p>
            <span class="subtle-label">{{
              item.updated_at | indonesian_datetime_format
            }}</span>
            <router-link
              class="text-right text-gray-500 block"
              :to="`/news/${item.id}`"
              >Selengkapnya</router-link
            >
          </div>
        </div>
      </div>

      <!-- rollback when there are no news -->
      <div v-else class="container max-w-xs shadow p-12 text-center mx-auto">
        <fa-layers class="text-6xl fa-6x">
          <fa-icon icon="file" class="text-gray-400 text-6xl"></fa-icon>
          <fa-icon
            icon="question-circle"
            class="text-gray-200 text-4xl"
          ></fa-icon>
        </fa-layers>
        <span class="block mt-6">Belum Ada Berita</span>
      </div>
    </section>

    <!-- pagination -->
    <div class="text-center text-xl pb-40">
      <button
        v-if="links.prev"
        class="mr-8 p-2 px-4 border border-gray-400 rounded-l-full"
        @click="fetchNews(links.prev)"
      >
        Sebelumnya
      </button>
      <button
        v-if="links.next"
        class="p-2 px-4 border border-gray-400 rounded-r-full"
        @click="fetchNews(links.next)"
      >
        Berikutnya
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      news: [],
      links: {},
    };
  },
  methods: {
    async fetchNews(url = "api/news") {
      let response = await axios.get(url);
      this.news = response.data.news;
      this.links = response.data.links;
    },
  },
  mounted() {
    this.fetchNews();
  },
  components: {
    MainMenu: () => import("@/components/MainMenu.vue"),
  },
  name: "NewsGallery",
};
</script>
